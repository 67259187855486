<template>
  <div id="round-overall-report" class="container-fluid pb-5">
    <div class="page-header mb-1">
      <strong>ดูของรวม</strong>
    </div>
    <b-card no-body class="mb-1 position-relative">
      <b-card-header header-tag="nav">
        <h5 class="card-title">
          <i class="fas fa-exclamation-triangle text-danger mr-2"></i>
          <span class="text-dark mr-2">เฉพาะงวด</span>
          <span class="text-primary mr-2">{{ roundName }}</span>
          <span class="text-dark mr-2">วันที่</span>
          <span class="text-primary mr-2">{{ roundDate }}</span>
          <small class="text-secondary">(เปลี่ยนได้ที่แถบเมนูด้านบน)</small>
        </h5>

        <b-nav card-header tabs>
          <b-nav-item @click="activeTab='OverallTop'" :active="activeTab==='OverallTop'">เลขบน</b-nav-item>
          <b-nav-item @click="activeTab='OverallBottom'" :active="activeTab==='OverallBottom'">เลขล่าง</b-nav-item>
        </b-nav>
      </b-card-header>

      <RoundOverallTop
        v-if="activeTab==='OverallTop'"
        :data="numberItems"
      />

      <RoundOverallBottom
        v-if="activeTab==='OverallBottom'"
        :data="numberItems"
      />
    </b-card>
  </div>
</template>
<script>
import ReportService from "@/services/ReportService"
import moment from '@/helpers/moment'
import cAlert from '@/helpers/alert'

import RoundOverallTop from './components/RoundOverallTop'
import RoundOverallBottom from './components/RoundOverallBottom'

export default {
  name: 'RoundOverall',
  components: {
    RoundOverallTop,
    RoundOverallBottom
  },
  data() {
    return {
      data: null,
      isLoading: false,
      activeTab: 'OverallTop'
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      return `[${this.data.round.note.groupTitle}] ${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    },
    dataItems() {
      return this.data?.items || []
    },
    numberItems() {
      return this.dataItems.reduce((items, item)=>{
        items[item._id.type][item._id.number] = {
          length: item.count,
          amount: item.amount,
          received: item.agentAmount
        }
        return items
      }, {
        threeNumberTop: {},
        threeNumberTode: {},
        twoNumberTop: {},
        twoNumberBottom: {},
        runTop: {},
        runBottom: {}
      })
    },
  },
  watch: {
    roundId(n, o) {
      if(n)
        this.getReport()
    }
  },
  methods: {
    getReport() {
      this.isLoading = true
      ReportService.getRoundOverall(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.getReport()
  }
}
</script>
<style lang="scss" scoped>
#round-overall-report {
  .table {
    thead {
      tr {
        th {
          text-align: center;
        }
        td {
          text-align: center;
          font-size: 85%;
        }
      }
    }
  }
}
</style>
