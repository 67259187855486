<template>
  <div id="round-overall-top" class="p-2">

    <div class="my-4">
      <b-input-group size="md" prepend="ดูหมายเลข" class="mb-2">
        <b-form-input v-model="findNumbers"></b-form-input>
      </b-input-group>

      <b-input-group prepend="เรียงลำดับ" size="md" class="mb-2">
        <b-input-group-prepend>
          <b-button :variant="sortBy==='number'?'info':'outline-info'" @click="sortBy='number';sortOrder='asc'">หมายเลข</b-button>
          <b-button :variant="sortBy==='bill'?'info':'outline-info'" @click="sortBy='bill';sortOrder='desc'">จำนวนโพย</b-button>
          <b-button :variant="sortBy==='amount'?'info':'outline-info'" @click="sortBy='amount';sortOrder='desc'">ยอดทั้งหมด</b-button>
          <b-button :variant="sortBy==='received'?'info':'outline-info'" @click="sortBy='received';sortOrder='desc'">ถือหุ้น</b-button>
        </b-input-group-prepend>
        <b-input-group-prepend is-text>
          เรียงจาก
        </b-input-group-prepend>
        <b-input-group-append>
          <b-button :variant="sortOrder==='asc'?'info':'outline-info'" @click="sortOrder='asc'">น้อย > มาก</b-button>
          <b-button :variant="sortOrder==='desc'?'info':'outline-info'" @click="sortOrder='desc'">มาก > น้อย</b-button>
        </b-input-group-append>
      </b-input-group>

      <b-input-group prepend="จำนวนแถว" size="md" class="mb-2">
        <b-input-group-append>
          <b-button :variant="rowLength===10?'info':'outline-info'" @click="rowLength=10">10</b-button>
          <b-button :variant="rowLength===50?'info':'outline-info'" @click="rowLength=50">50</b-button>
          <b-button :variant="rowLength===100?'info':'outline-info'" @click="rowLength=100">100</b-button>
          <b-button :variant="rowLength===250?'info':'outline-info'" @click="rowLength=250">250</b-button>
          <b-button :variant="rowLength===500?'info':'outline-info'" @click="rowLength=500">500</b-button>
          <b-button :variant="rowLength===750?'info':'outline-info'" @click="rowLength=750">750</b-button>
          <b-button :variant="rowLength===1000?'info':'outline-info'" @click="rowLength=1000">ทั้งหมด</b-button>
        </b-input-group-append>
      </b-input-group>
    </div>

    <table class="table table-info table-sm table-bordered table-hover mb-0">
      <thead>
        <tr>
          <th colspan="4" class="border-right border-left border-top">3 ตัวบน</th>
          <th colspan="3" class="border-right border-top">3 ตัวโต๊ด</th>
          <th colspan="4" class="border-right border-top">2 ตัวบน</th>
          <th colspan="4" class="border-right border-top">วิ่งบน</th>
        </tr>
        <tr>
          <td width="100" class="border-left">หมายเลข</td>
          <td width="80">โพย</td>
          <td width="100">ยอดทั้งหมด</td>
          <td width="100" class="border-right">ถือหุ้น</td>

          <td width="80">โพย</td>
          <td width="100">ยอดทั้งหมด</td>
          <td width="100" class="border-right">ถือหุ้น</td>

          <td width="100">หมายเลข</td>
          <td width="80">โพย</td>
          <td width="100">ยอดทั้งหมด</td>
          <td width="100" class="border-right">ถือหุ้น</td>

          <td width="100">หมายเลข</td>
          <td width="80">โพย</td>
          <td width="100">ยอดทั้งหมด</td>
          <td width="100" class="border-right">ถือหุ้น</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.three.threeNumber+item.two.twoNumber+item.one.oneNumber">
          <td class="text-center text-primary border-left"><span class="badge badge-primary">{{item.three.threeNumber}}</span></td>

          <td
            class="text-center"
          >{{item.three.threeNumberTop.length | amountFormat(0, '-')}}</td>
          <td
            class="text-right"
            :class="[{'alert-primary':item.three.threeNumberTop.amount>0}]"
          >{{item.three.threeNumberTop.amount | amountFormat(2, '-')}}</td>
          <td
            class="text-right border-right"
            :class="[{'alert-success':item.three.threeNumberTop.received>0}]"
          >{{item.three.threeNumberTop.received | amountFormat(2, '-')}}</td>

          <td
            class="text-center"
          >{{item.three.threeNumberTode.length | amountFormat(0, '-')}}</td>
          <td
            class="text-right"
            :class="[{'alert-primary':item.three.threeNumberTode.amount>0}]"
          >{{item.three.threeNumberTode.amount | amountFormat(2, '-')}}</td>
          <td
            class="text-right border-right"
            :class="[{'alert-success':item.three.threeNumberTode.received>0}]"
          >{{item.three.threeNumberTode.received | amountFormat(2, '-')}}</td>

          <td class="text-center text-primary"><span class="badge badge-primary">{{item.two.twoNumber}}</span></td>
          <td
            class="text-center"
          >{{item.two.twoNumberTop.length | amountFormat(0, '-')}}</td>
          <td
            class="text-right"
            :class="[{'alert-primary':item.two.twoNumberTop.amount>0}]"
          >{{item.two.twoNumberTop.amount | amountFormat(2, '-')}}</td>
          <td
            class="text-right border-right"
            :class="[{'alert-success':item.two.twoNumberTop.received>0}]"
          >{{item.two.twoNumberTop.received | amountFormat(2, '-')}}</td>

          <td class="text-center text-primary"><span class="badge badge-primary">{{item.one.oneNumber}}</span></td>
          <td
            class="text-center"
          >{{item.one.runTop.length | amountFormat(0, '-')}}</td>
          <td
            class="text-right"
            :class="[{'alert-primary':item.one.runTop.amount>0}]"
          >{{item.one.runTop.amount | amountFormat(2, '-')}}</td>
          <td
            class="text-right border-right"
            :class="[{'alert-success':item.one.runTop.received>0}]"
          >{{item.one.runTop.received | amountFormat(2, '-')}}</td>
        </tr>
        <tr v-if="!items.length">
          <td colspan="15" class="text-center">ไม่มียอดแทง</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'RoundOverallTop',
  props: ['data'],
  data() {
    return {
      rowLength: 50,
      sortBy: 'number',
      sortOrder: 'asc',
      findNumbers: '',
      profit: null,
      profitAmount: 0
    }
  },
  computed: {
    threeNumberItems() {
      const numbers = []
      for(let i=0; i<=999; i++) {
        const threeNumber = i.toString().padStart(3, '0')
        numbers.push({
          threeNumber: threeNumber,
          threeNumberTop: {
            length: this.data.threeNumberTop?.[threeNumber]?.length || 0,
            amount: this.data.threeNumberTop?.[threeNumber]?.amount || 0,
            received: this.data.threeNumberTop?.[threeNumber]?.received || 0
          },
          threeNumberTode: {
            length: this.data.threeNumberTode?.[threeNumber]?.length || 0,
            amount: this.data.threeNumberTode?.[threeNumber]?.amount || 0,
            received: this.data.threeNumberTode?.[threeNumber]?.received || 0
          }
        })
      }

      return _.sortBy(numbers.filter((item)=>{
        if(this.findNumbers) {
          return `+${item.threeNumber}`.endsWith(this.findNumbers.slice(-3))
        }else{
          return item.threeNumberTop.length > 0 || item.threeNumberTode.length > 0
        }
      }), (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item.threeNumber) : -parseInt(item.threeNumber),
          bill: this.sortOrder==='asc' ? (item.threeNumberTop.length+item.threeNumberTode.length) : -(item.threeNumberTop.length+item.threeNumberTode.length),
          amount: this.sortOrder==='asc' ? (item.threeNumberTop.amount+item.threeNumberTode.amount) : -(item.threeNumberTop.amount+item.threeNumberTode.amount),
          received: this.sortOrder==='asc' ? (item.threeNumberTop.received+item.threeNumberTode.received) : -(item.threeNumberTop.received+item.threeNumberTode.received)
        }[this.sortBy]
      })
    },
    twoNumberItems() {
      const numbers = []
      for(let i=0; i<=99; i++) {
        const twoNumber = i.toString().padStart(2, '0')
        numbers.push({
          twoNumber: twoNumber,
          twoNumberTop: {
            length: this.data.twoNumberTop?.[twoNumber]?.length || 0,
            amount: this.data.twoNumberTop?.[twoNumber]?.amount || 0,
            received: this.data.twoNumberTop?.[twoNumber]?.received || 0
          }
        })
      }

      return _.sortBy(numbers.filter((item)=>{
        if(this.findNumbers) {
          return `+${item.twoNumber}`.endsWith(this.findNumbers.slice(-2))
        }else{
          return item.twoNumberTop.length > 0
        }
      }), (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item.twoNumber) : -parseInt(item.twoNumber),
          bill: this.sortOrder==='asc' ? item.twoNumberTop.length : -item.twoNumberTop.length,
          amount: this.sortOrder==='asc' ? item.twoNumberTop.amount : -item.twoNumberTop.amount,
          received: this.sortOrder==='asc' ? item.twoNumberTop.received : -item.twoNumberTop.received
        }[this.sortBy]
      })
    },
    oneNumberItems() {
      const numbers = []
      for(let i=0; i<=9; i++) {
        const oneNumber = i.toString()
        numbers.push({
          oneNumber: oneNumber,
          runTop: {
            length: this.data.runTop?.[oneNumber]?.length || 0,
            amount: this.data.runTop?.[oneNumber]?.amount || 0,
            received: this.data.runTop?.[oneNumber]?.received || 0
          }
        })
      }

      return _.sortBy(numbers.filter((item)=>{
        if(this.findNumbers) {
          return this.findNumbers.split("").includes(item.oneNumber)
        }else{
          return item.runTop.length > 0
        }
      }), (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item.oneNumber) : -parseInt(item.oneNumber),
          bill: this.sortOrder==='asc' ? item.runTop.length : -item.runTop.length,
          amount: this.sortOrder==='asc' ? item.runTop.amount : -item.runTop.amount,
          received: this.sortOrder==='asc' ? item.runTop.received : -item.runTop.received
        }[this.sortBy]
      })
    },
    items() {
      let groupMaxLength = this.threeNumberItems.length > this.twoNumberItems.length ? this.threeNumberItems.length : this.twoNumberItems.length
      groupMaxLength = groupMaxLength > this.oneNumberItems.length ? groupMaxLength : this.oneNumberItems.length

      const items = []
      for(let i=0; i<groupMaxLength; i++) {
        items.push({
          three: this.threeNumberItems[i] || {
            threeNumber: '',
            threeNumberTop: {
              length: 0,
              amount: 0,
              received: 0
            },
            threeNumberTode: {
              length: 0,
              amount: 0,
              received: 0
            }
          },
          two: this.twoNumberItems[i] || {
            twoNumber: '',
            twoNumberTop: {
              length: 0,
              amount: 0,
              received: 0
            }
          },
          one: this.oneNumberItems[i] || {
            oneNumber: '',
            runTop: {
              length: 0,
              amount: 0,
              received: 0
            }
          }
        })
      }

      if(!items.length)
        return []

      const chunks = _.chunk(items, this.rowLength)
      return chunks[0]
    }
  }
}
</script>
<style lang="scss" scoped>
#round-overall-top {
  .table {
    font-size: 90%;

    thead {
      tr {
        th, td {
          text-align: center;
          vertical-align: middle;
        }
      }
    }
    tbody {
      background-color: #FFF;
    }

    th, td {
      .badge {
        font-size: 100%;
      }
      &.border-right {
        border-right: 3px solid #86cfda !important;
      }
      &.border-top {
        border-top: 3px solid #86cfda !important;
      }
      &.border-left {
        border-left: 3px solid #86cfda !important;
      }
      &.border-bottom {
        border-bottom: 3px solid #86cfda !important;
      }
    }
  }
}
</style>
